<template>
    <section class="pt-3">
        <div class="row mx-3 px-3 br-8 py-3 bg-white align-items-center">
            <div class="text-general f-17 f-600">
                Términos y condiciones aceptadas
            </div>
            <div class="col-auto ml-auto">
                <div class="bg-fondo text-general f-15 px-2 br-12 py-2">
                    {{$config.vendedor}}
                    <b class="f-18 ml-2">
                        {{ agregarSeparadoresNumero(totales.leecheros) }}
                    </b>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-fondo text-general f-15 px-2 br-12 py-2">
                    {{$config.cliente}}
                    <b class="f-18 ml-2">
                        {{ agregarSeparadoresNumero(totales.clientes) }}
                    </b>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-fondo text-general f-15 px-2 br-12 py-2">
                    {{$config.vendedor}} sin aceptar
                    <b class="f-18 text-general-red ml-2">
                        {{ agregarSeparadoresNumero(totales.leecherosSinAceptar) }}
                    </b>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-fondo text-general f-15 px-2 br-12 py-2">
                    {{$config.cliente}} sin aceptar
                    <b class="f-18 text-general-red ml-2">
                        {{ agregarSeparadoresNumero(totales.clientesSinAceptar) }}
                    </b>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12">
                <tabla-general
                :data="dataTable"
                class-header="text-general f-16"
                :columnas="dataColumns"
                :mostrar-buscador="false"
                :usar-paginacion="true" 
                alto="calc(100vh - 408px)"
                >  
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 py-2 f-500">
                            Usuarios que aceptaron los términos
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        label="T&C"
                        class-name="text-center"
                        width="80"
                        >
                            <template slot-scope="{ row }">
                                <div class="row mx-0 justify-center">
                                    <div class="position-relative cr-pointer" style="width:25px;height:25px;" @click="historialAceptacion(row)">
                                        <div v-if="row.punto" class="bg-general position-absolute rounded-circle" style="width:10px;height:10px;right:-4px;" />
                                        <i class="icon-book-open-variant text-general f-20" />
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-historial-aceptacion ref="historialAceptacion" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import FaqsAceptadas from "~/services/faqs/faqs_aceptadas";
export default {
    components: {
        modalHistorialAceptacion: () => import('./partials/modalHistorialAceptacion')
    },
    data(){
        return {
            dataTable: [
                {
                    nombre: 'Juan Carlos Villegas Ardila',
                    tipo_usuario: 'Leechero',
                    fecha_aceptacion: '13 Abr. 2020',
                    fecha_registro: '13 Abr. 2020'
                }
            ],
            dataColumns: [
                { valor: 'nombre', titulo: 'Nombre' },
                { valor: 'tipo_nombre', titulo: 'Tipo de usuario', class: 'text-center' },
                { valor: 'fecha_aceptacion', titulo: 'Fecha de aceptación', class: 'text-center' },
                { valor: 'fecha_registro', titulo: 'Fecha de registro', class: 'text-center' },
            ],
            totales: {
                leecheros: 0,
                leecherosSinAceptar: 0,
                clientes: 0,
                clientesSinAceptar: 0,
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        id_cedis(val, oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
    },
    mounted(){
        this.montar()
    },
    methods:{
        async montar(){
            try {
                if(this.id_cedis == null){
                    setTimeout(async() => {
                        await this.getFaqsAceptadas()
                    }, 1000);
                }else{
                    await this.getFaqsAceptadas()
                }
            } catch (error){
                console.log(error)
            }
        },
        historialAceptacion(row){
            this.$refs.historialAceptacion.toggle(row);
        },
        async getFaqsAceptadas(){
            try {
                const params = {
                    id_cedis: this.id_cedis
                }
                const { data } = await FaqsAceptadas.getFaqsAceptadas(params)
                data.usersAceptadas.map(el => {
                    el.fecha_registro = this.formatearFecha(el.fecha_registro, 'D MMM Y hh:mmA')
                    el.fecha_aceptacion = this.formatearFecha(el.fecha_aceptacion, 'D MMM Y hh:mmA')
                })
                this.dataTable = data.usersAceptadas
                this.totales = data.totales
            } catch (error){
                console.log(error);
            }
        }
    }
}
</script>
